import React from 'react'
import styled from 'styled-components'
import logo from '../../media/logo2.png'
import PropagateLoader from 'react-spinners/PropagateLoader'

const Loadingpage = () => {
    const Container = styled.div`
        width: 100vw;
        height: 100vh;
        background: #000;
        display: flex;
        flex-direction : column;
        justify-content: center;
        align-items: center;
    `;
    const Logo = styled.img`
        width: 150px;
        height: 150px;
        object-fit: contain;
        margin-bottom: 15px;
    `;
  return (
    <Container>
        <Logo src={logo} />        
        <PropagateLoader color="#0F4BB9" />
    </Container>
  )
}

export default Loadingpage