import { createGlobalStyle ,css} from "styled-components";

export const fontFaces = css`
    @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fjalla+One&family=Jost:wght@300;400;500&family=Manrope:wght@300;400;500;600;700&family=Quicksand:wght@400;500;600;700&family=Shadows+Into+Light&family=Space+Mono&display=swap');
`;

const GlobleStyle = createGlobalStyle`
    ${fontFaces};
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /* font-family: 'Montserrat', sans-serif; */
    }

    html, body{
        overflow-x: hidden;
        scroll-behavior: smooth;
        background: #fff;
    }
    body::-webkit-scrollbar {
        width: 5px;
        background: none;
        display: none;
    }
    
    body::-webkit-scrollbar-track {
        
    }
    
    body::-webkit-scrollbar-thumb {
        background-color: #D4AF37;
        outline: 1px solid slategrey;
    }
`;

export default GlobleStyle;